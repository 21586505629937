import LazyImage from '@/components/lazy-image'
import { useUserStore } from '@/store/user'
import { ReactNode, useEffect, useState } from 'react'
import { oss_svg_image_domain_address } from '@/constants/oss'
import classNames from 'classnames'
import { getThemeSuffix } from '@/helper/common'
import { useVipUserInfo } from '@/hooks/features/vip/vip-perks'
import { useGetAvatarFrames } from '@/hooks/features/vip/vip-avatar'
import styles from './index.module.css'

function UserAvatar({
  tagIcon,
  width = 90,
  height = 90,
  hasFrame,
  setFrameLvl,
  isUser = false,
  avatarImg,
  isFetch = false,
  className,
  onClick,
}: {
  tagIcon?: ReactNode
  width?: number
  height?: number
  hasFrame?: boolean
  setFrameLvl?: string
  /** 头像 url */
  avatarImg?: string
  isUser?: boolean
  /** 是否需要请求 */
  isFetch?: boolean
  className?: string
  /** 头像点击事件 */
  onClick?: (e) => void
}) {
  const { isLogin } = useUserStore()
  const userInfo = useUserStore()?.userInfo
  const { userConfig, fetchApi } = useVipUserInfo()
  const { frames } = useGetAvatarFrames(isFetch)

  const [selectedFrame, setselectedFrame] = useState('')
  const noAvatarUrl = `${oss_svg_image_domain_address}vip/image_vip_no_avatar${getThemeSuffix()}.png`
  const onGetUrl = () => {
    if (avatarImg) return avatarImg

    if (isLogin && isUser && userInfo?.avatarPath) return userInfo?.avatarPath

    return noAvatarUrl
  }
  useEffect(() => {
    isFetch && fetchApi()
  }, [])

  useEffect(() => {
    if (setFrameLvl !== undefined || setFrameLvl !== null) {
      const selected = frames?.find(frame => frame.levelCode === setFrameLvl)
      const frame = selected?.vipIcon || ''
      setselectedFrame(frame)
    }
  }, [setFrameLvl, frames])

  return (
    <div className={classNames(styles.scoped, className)} style={{ width, height }}>
      {hasFrame &&
        (userConfig?.avatarIcon || setFrameLvl) &&
        (setFrameLvl ? (
          <LazyImage width={width} height={height} className="user-avatar-frame" src={selectedFrame} />
        ) : (
          <LazyImage width={width} height={height} className="user-avatar-frame" src={userConfig?.avatarIcon || ''} />
        ))}
      <LazyImage
        className={classNames('user-avatar', {
          'cursor-pointer': !!onClick,
        })}
        style={{ width, height }}
        src={onGetUrl()}
        onClick={e => onClick?.(e)}
      />
      {tagIcon && <span className="avatar-status-tag">{tagIcon}</span>}
    </div>
  )
}

export default UserAvatar
